<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Add Booking</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col>
                <h3 class="primary--text">1. Service Info</h3>
                <p class="mb-4">Please enter the customer's information first.</p>
              </v-col>
            </v-row>

            <CustomerBasicFormFields
              :form="form"
            />

            <v-divider class="mb-8 mt-3" />

            <BookingLocationFields
              ref="locationFields"
              :form="form"
              :job-types="jobTypes"
              @update:return_date="date => { form.return_date = date.format('YYYY-MM-DD') }"
              @update:return_address="val => { form.return_address = val }"
              @update:return_postcode="val => { form.return_postcode = val }"
              @hasAvailability="handleAvailability"
            />

            <v-divider
              v-if="form.bikes.length > 0"
              class="mb-8 mt-4"
            />

            <BikeFormFields
              v-for="(bike, key) in form.bikes"
              :key="key"
              :bike="bike"
              :form="form"
              :services="services"
              :job-types="jobTypes"
              :autofocus="false"
              hide-job-type
            />

            <v-divider
              v-if="form.bikes.length > 0"
              class="mb-5 mt-4"
            />

            <AddBookingSettings
              v-if="form.bikes.length > 0"
              :form="form"
            />

            <FormErrors ref="formErrors" />

          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="createBooking"
          >
            Add Booking <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Booking created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the booking.
    </SnackBar>

  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import CustomerBasicFormFields from '@/components/customers/CustomerBasicFormFields'
import BikeFormFields from '@/components/bikes/BikeFormFields'
import BookingLocationFields from '@/components/bookings/BookingLocationFields'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import AddBookingSettings from '@/components/bookings/AddBookingSettings'

export default {
  name: 'AddBookingDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    CustomerBasicFormFields,
    BikeFormFields,
    BookingLocationFields,
    SnackBar,
    FormErrors,
    AddBookingSettings
  },

  data () {
    return {
      loading: false,
      services: [],
      jobTypes: [],
      form: {
        send_payment_link: true,
        return_to_different_address: false,
        send_payment_link_reminders: true,
        channel: 'CHANNEL_PHONE',
        job_type: 'JOB_TYPE_C&R',
        bikes: [],
        number_of_bikes: 1,
        return_date: null,
        collection_date: null,
      }
    }
  },

  mounted () {
    this.getServices()
    this.getJobTypes()
  },

  methods: {
    closeDialog () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$refs.locationFields.clear()
      this.form.bikes = []
      this.form.send_payment_link = true
      this.form.return_to_different_address = false
      this.form.channel = 'CHANNEL_PHONE'
      this.form.job_type = 'JOB_TYPE_C&R'
      this.form.send_payment_link_reminders = true
      this.form.number_of_bikes = 1
      this.form.return_date = null
      this.form.collection_date = null
      this.$emit('update:dialog', false)
    },

    getServices () {
      this.$api.get({
        path: 'services',
        params: {
          orderBy: 'price',
          ascending: 1,
          limit: 100,
          show_all: true
        }
      })
        .then(({ data }) => {
          this.services = data
        })
    },

    handleAvailability (data) {
      this.form.collection_date = data.first_collection.format('YYYY-MM-DD')
      var bikes = [...Array(this.form.number_of_bikes).keys()]
      bikes.forEach(() => {
        this.form.bikes.push({
          additional_authorised_spend: 0,
          job_type: this.form.job_type,
          type: (this.form.job_type === 'JOB_TYPE_FLEET_ONSITE') ? 'TYPE_OTHER' : null
        })
      })
      if (this.form.bikes.length > this.form.number_of_bikes) {
        this.form.bikes.length = this.form.number_of_bikes
      }
    },

    getJobTypes () {
      this.$api.get({
        path: 'job-types'
      })
        .then(types => {
          Object.keys(types).forEach((key) => {
            this.jobTypes.push({
              label: types[key],
              value: key
            })
          })
        })
    },

    createBooking () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$refs.formErrors.clear()
        this.$api.persist('post', {
          path: 'bookings',
          object: this.form
        })
          .then(({ data: booking }) => {
            this.$refs.successBar.open()
            this.closeDialog()
            this.$router.push({ name: 'bookings.show', params: { bookingId: booking.id }})
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
