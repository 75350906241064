import axios from 'axios'

let Api = class Api {
  options = {}

  axiosInstance = null

  defaultOptions = {
    baseUrl: '',
    apiPath: '/api/',
    serverUnauthMessage: 'We could not complete the request, because you are not authorised to do so.',
    serverErrorMessage: 'We could not access the server at this time. Please try again. If the issue persists, please contact support.'
  }

  constructor (instance, options) {
    this.instance = instance
    this.options = {
      ...this.defaultOptions,
      ...options
    }

    this.axiosInstance = axios.create({
        headers: {
            common: {
                'Authorization': `Bearer ${this.instance.$auth.accessToken}`,
            }
        }
    })

    this.axiosInstance.interceptors.request.use((config) => {
        config.params = config.params || {}
        config.params['hub_id'] = this.instance.$hubs.currentHub?.id
        return config
    })
  }

  get (data) {
    if (!data.params) {
      data.params = {}
    }

    if (!data.url) {
      data.url = this.options.baseUrl + this.options.apiPath + data.path
    }

    data.params.limit = data.params.limit ? data.params.limit : 15
    data.params.ascending = data.params.ascending ? data.params.ascending : 0
    data.params.orderBy = data.params.orderBy ? data.params.orderBy : 'id'

    return new Promise((resolve, reject) => {

      this.axiosInstance.get(data.url, { params: data.params })
        .then((response) => {
          if (data.raw) {
            resolve(response)
          } else {
            resolve(response.data)
          }
        })
        .catch((error) => {
          reject(this.errorAdapter(error))
        })
    })
  }

  delete (data) {
    if (!data.url) {
      data.url = this.options.baseUrl + this.options.apiPath + data.path
    }

    return new Promise((resolve, reject) => {

      this.axiosInstance.delete(data.url, data.params ? { params: data.params } : '')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(this.errorAdapter(error))
        })
    })
  }

  persist (method, data) {
    if (!data.params) {
      data.params = {}
    }

    if (!data.url) {
      data.url = this.options.baseUrl + this.options.apiPath + data.path
    }

    return new Promise((resolve, reject) => {

      this.axiosInstance[method](data.url, data.object, data.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(this.errorAdapter(error))
        })
    })
  }

  persistMultiple (method, requests) {
    var functionedRequests = []

    requests.forEach(request => {
      var data = {
        ...{
          params: {},
          url: this.options.baseUrl + this.options.apiPath + request.path
        },
        ...request
      }
      functionedRequests.push(this.axiosInstance[method](data.url, data.object, data.params))
    })

    return new Promise((resolve, reject) => {
      Promise.all(functionedRequests)
        .then(data => {
          var resolved = []
          data.forEach(part => {
            resolved.push(part.data)
          })
          resolve(resolved)
        })
        .catch(error => {
          reject(this.errorAdapter(error))
        })
    })
  }

  errorAdapter (error) {
    error = error || {}
    var response = error.response ? error.response : {}
    error = response.status ? error.response : error
    var data = error.data ? error.data : error.message

    // if (error.status === 401) {
    //   this.instance.$router.push({ name: 'login', query: { intended: window.location.href }})
    // }

    return error ? (
      (typeof data === 'object' && error.status === 422)
        ? data
        : (error.status === 403 || error.status === 401)
          ? {
            message: data.message || this.options.serverUnauthMessage,
            code: error.status
          }
          : {
            message: data.message || this.options.serverErrorMessage,
            errors: {
              'server': ['Please use this error code in any support queries. Error Code: ' + error.status]
            },
            code: error.status
          }
    ) : {
      message: error.message,
      errors: {
        'server': error.message
      },
      code: error.status
    }
  }
}

export default Api.install = function (Vue, options) {
  Vue.mixin({
    computed: {
      $api () {
        return new Api(this, options)
      }
    }
  })
}
