import axios from 'axios'
import router from '@/router/'
import auth from '@/auth'

// initial state
const state = {
  authModal: false,
  token: null,
  refreshToken: null
}

// getters
const getters = {
  showAuthModal: (state) => {
    return state.authModal
  },

  isAuthenticated: (state, getters) => {
    return getters.accessToken ? true : false
  },

  accessToken: (state) => {
    return state.token
  }
}

// actions
const actions = {
  login () {
    var clientId = process.env.VUE_APP_CLIENT_ID
    var redirect = process.env.VUE_APP_CALLBACK_URL
    var scope = 'engine:list:resources engine:read:resources engine:create:resources engine:update:resources engine:delete:resources inventory:list:resources inventory:read:resources inventory:create:resources inventory:update:resources inventory:delete:resources auth:list:resources auth:read:resources'
    var state = auth.generateRandomString(40)
    var codeVerifier = auth.generateCodeVerifier()
    var codeChallenge = auth.generateCodeChallenge(codeVerifier)
    var url = `${process.env.VUE_APP_AUTH_BASE_URL}/oauth/authorize?client_id=${clientId}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`

    window.localStorage.setItem('auth_state', state)
    window.localStorage.setItem('auth_verifier', codeVerifier)
    window.location.replace(url)
  },

  getToken ({ commit }, obj) {
    return new Promise((resolve, reject) => {
      // const state = window.localStorage.getItem('auth_state')
      const codeVerifier = window.localStorage.getItem('auth_verifier')
      var clientId = process.env.VUE_APP_CLIENT_ID
      var redirect = process.env.VUE_APP_CALLBACK_URL
      axios.post(`${process.env.VUE_APP_AUTH_BASE_URL}/oauth/token`, {
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: redirect,
        code_verifier: codeVerifier,
        code: obj.code
      })
        .then(({ data }) => {
          commit('setToken', data.access_token)
          commit('setRefreshToken', data.refresh_token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          // Logout of the web session
          // axios.post(`${process.env.VUE_APP_API_BASE_URL}/logout`, {}, { withCredentials: true })
        })
      })
  },

  logout ({ commit, state, dispatch }) {
    dispatch('user/removeAllUsers', {}, { root: true })
    state.token = null
    state.refreshToken = null
    commit('handleSuccessfulLogout')
  }
}

// mutations
const mutations = {
  setToken (state, token) {
    state.token = token
  },

  setRefreshToken (state, refreshToken) {
    state.refreshToken = refreshToken
  },

  handleSuccessfulLogout () {
    router.replace({ name: 'login' })
  },

  showAuthModal (state) {
    state.authModal = true
  },

  hideAuthModal (state) {
    state.authModal = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
