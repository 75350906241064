<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="form.job_type"
          :rules="[v => !!v || 'Job type is required']"
          :items="jobTypesWithoutComplaints"
          label="Job Type"
          item-text="label"
          item-value="value"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        v-if="form.job_type === 'JOB_TYPE_FLEET_ONSITE'"
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.collection_postcode"
          :rules="[v => !!v || 'Visit postcode is required']"
          label="Visit Postcode"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-row v-if="form.job_type !== 'JOB_TYPE_FLEET_ONSITE'">
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.collection_postcode"
          :rules="[v => !!v || 'Collection postcode is required']"
          label="Collection Postcode"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="form.number_of_bikes"
          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          label="Bikes For Repair"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :loading="loading"
          color="primary"
          small
          @click="checkAvailability('collectionPostcode')">
          Check Availability
        </v-btn>

        <v-alert
          v-if="collectionAddressError.errors.postcode[0]"
          dense
          outlined
          class="mt-4 mb-0"
          type="error"
          border="top"
        >
          {{ collectionAddressError.errors.postcode[0] }}
        </v-alert>

        <v-alert
          v-if="collection_postcode_data.postcode"
          outlined
          dense
          class="mt-4 mb-0"
          border="top"
          type="success"
        >
          <strong>All good!</strong> We have availability for {{ form.number_of_bikes === 1 ? 'this bike' : 'these bikes' }}. Please choose a {{ form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'visit' : 'collection' }} address below.
        </v-alert>
      </v-col>
    </v-row>

    <v-divider
      v-if="collection_addresses.length > 0"
      class="mb-6 mt-6"
    />

    <v-row  
      v-if="collection_addresses.length > 0"
    >
      <v-col>
        <h3 class="primary--text">2. {{ form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'Visit' : 'Collection' }} Address</h3>
        <p>Please choose the address we will be {{ form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'visiting' : 'collecting from.' }}</p>
      </v-col>
    </v-row>

    <v-row
      v-if="collection_addresses.length > 0"
      class="pt-0"
    >
      <v-col>
        <v-select
          v-model="form.collection_address"
          :items="collection_addresses"
          :label="`${form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'Visit' : 'Collection'} Address`"
          :rules="[v => !!v || `Please choose a ${form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'visit' : 'collection'} address`]"
          required
          outlined
          dense
        />
        <v-checkbox
          v-if="form.job_type !== 'JOB_TYPE_FLEET_ONSITE'"
          v-model="form.return_to_different_address"
          label="Return to different address"
        />
      </v-col>
    </v-row>

    <v-divider
      v-if="collection_addresses.length > 0"
      class="mb-6 mt-2"
    />

    <v-row
      v-if="form.return_to_different_address"
    >
      <v-col>
        <h2>Return Address</h2>
        <p>Please choose the address we will be collecting from.</p>
      </v-col>
    </v-row>

    <v-row v-if="form.return_to_different_address">
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.return_postcode"
          :rules="[v => !!v || 'Return postcode is required']"
          label="Return Postcode"
          outlined
          required
          dense
        />

        <v-btn
          :loading="loading"
          :class="{ 'mb-6': return_addresses.length === 0 }"
          color="primary"
          small
          @click="checkAvailability('returnPostcode')">
          Check Availability
        </v-btn>
      </v-col>
    </v-row>

    <v-alert
      v-if="returnAddressError.errors.postcode[0] && form.return_to_different_address"
      dense
      outlined
      class="mt-4 mb-0"
      type="error"
      border="top"
    >
      {{ returnAddressError.errors.postcode[0] }}
    </v-alert>

    <v-alert
      v-if="return_addresses.length > 0 && form.return_to_different_address"
      outlined
      dense
      class="mt-4 mb-0"
      border="top"
      type="success"
    >
      <strong>All good!</strong> Please choose a return address below.
    </v-alert>

    <v-row
      v-if="form.return_to_different_address && return_addresses.length > 0"
    >
      <v-col>
        <v-select
          v-model="form.return_address"
          :items="return_addresses"
          label="Return Address"
          :rules="[v => !!v || 'Please choose a collection address']"
          required
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-divider
      v-if="form.return_to_different_address"
      class="mb-6"
    />

    <BookingDatesSelector
      ref="datesSelector"
      :form="form"
      :postcode-data="collection_postcode_data"
      :collection-days="collection_days"
      :return-days="return_days"
      @update:return_date="date => { $emit('update:return_date', date) }"
    />
  </div>
</template>
<script type="text/javascript">
import BookingDatesSelector from '@/components/bookings/BookingDatesSelector'
import filter from 'lodash.filter'

export default {
  name: 'BookingLocationFields',

  props: {
    form: {
      type: Object,
      required: true
    },

    jobTypes: {
      type: Array,
      required: true
    }
  },

  components: {
    BookingDatesSelector
  },

  data () {
    return {
      collection_postcode_data: {},
      collection_days: [],
      return_days: [],
      collection_addresses: [],
      return_addresses: [],
      loading: false,
      collectionAddressError: {
        errors: {
          postcode: []
        }
      },
      returnAddressError: {
        errors: {
          postcode: []
        }
      }
    }
  },

  watch: {
    'form.return_to_different_address': function () {
      if (!this.form.return_to_different_address) {
        this.$emit('update:return_address', null)
        this.$emit('update:return_postcode', null)
        this.return_addresses = []
        this.returnAddressError = {
          errors: {
            postcode: []
          }
        }
      }
    }
  },

  computed: {
    jobTypesWithoutComplaints () {
      return filter(this.jobTypes, (jobType) => {
        return !jobType.value.includes('COMPLAINT')
      })
    }
  },

  methods: {
    clear () {
      this.collectionAddressError = {
        errors: {
          postcode: []
        }
      }
      this.returnAddressError = {
        errors: {
          postcode: []
        }
      }
      this.collection_postcode_data = {}
      this.collection_addresses = []
      this.collection_days = []
      this.return_days = []
      this.return_addresses = []
      this.$refs.datesSelector.clear()
    },

    checkAvailability (type) {
      var isCollection = type === 'collectionPostcode'
      var postcode = isCollection ? this.form.collection_postcode : this.form.return_postcode
      this.loading = true
      this.$api.persist('post', {
        path: 'availability',
        object: {
          postcode: postcode,
          number_of_bikes: this.form.number_of_bikes,
          types: [this.form.job_type]
        }
      })
        .then(({ data: data }) => {
          if (isCollection) {
            this.collection_postcode_data = data.postcode_data
            this.collection_days = data.collection_days
            this.return_days = data.return_days
            this.collection_addresses = data.addresses
            if (this.collection_days.length > 0 && this.return_days.length > 0) {
              var availableCollectionDates = filter(this.collection_days, day => {
                return day.available
              })
              this.$emit('hasAvailability', {
                ...data,
                ...{
                  first_collection: this.$moment(availableCollectionDates[0].date)
                }
              })
            }
            this.collectionAddressError = {
              errors: {
                postcode: []
              }
            }
          } else {
            this.return_addresses = data.addresses
            this.returnAddressError = {
              errors: {
                postcode: []
              }
            }
          }
        })
        .catch(error => {
          if (isCollection) {
            this.collectionAddressError = error
            this.collection_postcode_data = {}
            this.collection_addresses = []
            this.$emit('hasNoAvailability', error)
          } else {
            this.returnAddressError = error
            this.return_addresses = []
          }
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
