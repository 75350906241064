<template>
  <span>
    <AlgoliaSearch
      v-model="search"
      :show-errors="false"
      ref="algoliaSearch"
      label=""
      index="admin_search"
      placeholder="Search Atlas (press /)"
      background-colour="grey lighten-3"
      icon="mdi-magnify"
      item-text="job_number"
      item-value="id"
      hide-details
      full-width
      light
      @change="handleChange"
    >
      <template slot-scope="data">
          <template v-if="data.item">
            <template v-if="data.item._type === 'TYPE_BOOKING_BIKE'">
              <v-list-item-content>
                <!-- <v-chip x-small outlined color="primary">{{ data.item._type_name }}</v-chip> -->
                <v-list-item-title v-if="data.item._highlightResult.job_number" class="search_highlight"><strong v-html="data.item._highlightResult.job_number.value" /></v-list-item-title>
                <v-list-item-title v-else class="search_highlight"><strong>-</strong></v-list-item-title>
                <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.customer_name.value" />
                <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.customer_email.value" />
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text v-if="data.item._highlightResult.manafacturer" class="search_highlight"><span v-html="data.item._highlightResult.manafacturer.value" /></v-list-item-action-text>
                <v-list-item-action-text v-if="data.item._highlightResult.model" class="search_highlight"><span v-html="data.item._highlightResult.model.value" /> <span v-if="data.item._highlightResult.colour" v-html="`- ${data.item._highlightResult.colour.value}`" /></v-list-item-action-text>
              </v-list-item-action>
            </template>

            <template v-if="data.item._type === 'TYPE_USER'">
              <v-list-item-content>
                <v-list-item-title class="search_highlight"><strong v-html="data.item._highlightResult.name.value" /></v-list-item-title>
                <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.email.value" />
              </v-list-item-content>
            </template>
          </template>
        </template>
    </AlgoliaSearch>
  </span>
</template>
<script type="text/javascript">
import AlgoliaSearch from '@/components/algolia-search/AlgoliaSearch'

export default {
  name: 'AppSearch',

  components: {
    AlgoliaSearch
  },

  data () {
    return {
      search: null
    }
  },

  created() {
    window.addEventListener('keyup', (e) => {
      if (e.code === 'Slash') {
        this.$refs.algoliaSearch.focus()
      }
    })
  },

  methods: {
    handleChange (item) {
      if (item) {
        if (item._type === 'TYPE_BOOKING_BIKE') {
          this.$router.push({ name: 'bookings.show', params: { bookingId: item.booking_id } })        
        }
        if (item._type === 'TYPE_USER') {
          this.$router.push({ name: 'customers.show', params: { customerId: item.id } })        
        }
      }
    }
  }
}
</script>
<style>
body {
  font-family: sans-serif;
  padding: 1em;
}
</style>
