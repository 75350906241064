<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        :color="getColour(day.remaining / day.total)"
        small
        dense
      >
        mdi-information-outline
      </v-icon>
    </template>
    <span v-if="!day.available">{{ day.message }} {{ day.taken > 0 ? `All ${day.total} slots taken.` : '' }}</span>
    <span v-else>{{ day.remaining }} slots remaining out of {{ day.total }} total slots.</span>
  </v-tooltip>
</template>
<script type="text/javascript">
export default {
  name: 'BookingDateSelectorTooltip',

  props: {
    day: {
      type: Object,
      required: true
    }
  },

  methods: {
    getColour (percentage) {
      if (percentage === 1) {
        return 'green'
      }

      if (percentage > 0.4) {
        return 'orange'
      }

      if (percentage > 0) {
        return 'red'
      }

      return 'grey'
    }
  }
}
</script>
