<template>
  <v-navigation-drawer
    v-if="$route.name !== 'login' && $route.name !== 'auth.callback'"
    v-model="innerDrawer"
    app
  >
    <v-list
      nav
      dense
    >
      <v-list-item
        :to="{ name: 'dashboard' }"
        @click="$emit('update:drawer', false)"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_BOOKINGS
        @click="$emit('update:drawer', false)"
        :to="{ name: 'bookings', query: { filters: JSON.stringify([
          {
            key: { key: 'payment_status', label: 'Payment Status', type: 'Select' },
            operator: { key: 'is', label: 'is' },
            value: { key: 'PAYMENT_SUCCEEDED', label: 'Payment Succeeded' }
          }
        ])}}"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-order-bool-descending</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Bookings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_SERVICES
        @click="$emit('update:drawer', false)"
        :to="{ name: 'services' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-wrench</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_PAYMENTS
        @click="$emit('update:drawer', false)"
        :to="{ name: 'payments' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-credit-card-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Payments</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_DISCOUNT_CODES
        @click="$emit('update:drawer', false)"
        :to="{ name: 'discount-codes' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-sale</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Discount Codes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_AVAILABILITIES
        @click="$emit('update:drawer', false)"
        :to="{ name: 'availabilities' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-clock-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Availability</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_VEHICLES
        @click="$emit('update:drawer', false)"
        :to="{ name: 'vehicles' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-truck-fast</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Vehicles</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_MANIFESTS
        @click="$emit('update:drawer', false)"
        :to="{ name: 'manifests' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-map-clock-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>C&R Manifests</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_USERS
        @click="$emit('update:drawer', false)"
        :to="{ name: 'customers' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_USERS
        :to="{ name: 'admin-users' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-shield-account</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Admin Users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-user-can:LIST_ROLES
        @click="$emit('update:drawer', false)"
        :to="{ name: 'roles' }"
        color="primary"
        exact
        link
      >
        <v-list-item-action>
          <v-icon>mdi-shield-lock</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Roles</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        prepend-icon="mdi-account-circle"
      >
        <template v-slot:activator>
          <v-list-item-title>My Account</v-list-item-title>
        </template>

        <!-- THEME -->
        <v-list-item
          link
          @click="$vuetify.theme.dark = !$vuetify.theme.dark; $auth.toggleDarkMode()"
        >
          <v-list-item-action>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Theme</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- LOGOUT -->
        <v-list-item link @click="$emit('update:drawer', false); $auth.logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ __('Logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </v-list>
  </v-navigation-drawer>
</template>
<script type="text/javascript">
export default {
  name: 'AppNavigation',

  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    innerDrawer: {
      get () {
        return this.drawer
      },
      set (localValue) {
        this.$emit('update:drawer', localValue)
      }
    }
  }
}
</script>
