import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import hub from '@/store/modules/hub'
import Api from '@/api/'

Vue.use(Vuex)
Vue.use(Api, { baseUrl: process.env.VUE_APP_API_BASE_URL })

export default new Vuex.Store({
  modules: {
    auth,
    user,
    hub
  },
  plugins: [createPersistedState({
    paths: [
      'auth.token',
      'user.user',
      'user.darkMode',
      'hub.hubs',
      'hub.currentHub',
    ]
  })]
})
