<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.customer_name"
          :rules="[v => !!v || 'Name is required']"
          label="Full Name"
          outlined
          required
          dense
          autofocus
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.customer_email"
          :rules="[v => !!v || 'Email is required']"
          label="Email"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.customer_mobile"
          :rules="[v => !!v || 'Mobile is required']"
          label="Mobile"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.customer_phone"
          label="Phone"
          outlined
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'CustomerBasicFormFields',

  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
