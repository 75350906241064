import Vue from "vue";
import VueRouter from "vue-router";
import middleware from '@/router/middleware'

const Dashboard = () => import(/* webpackChunkName: "dashboard-page" */ '@/views/Dashboard')
const Bookings = () => import(/* webpackChunkName: "bookings-page" */ '@/views/bookings/Bookings')
const Payments = () => import(/* webpackChunkName: "payments-page" */ '@/views/payments/Payments')
const BookingBikes = () => import(/* webpackChunkName: "booking-bikes-page" */ '@/views/booking-bikes/BookingBikes')
const LoginPage = () => import(/* webpackChunkName: "login-page" */ '@/views/LoginPage')
const NotFoundPage = () => import(/* webpackChunkName: "not-found-page" */ '@/views/errors/NotFoundPage')
const UnauthorisedPage = () => import(/* webpackChunkName: "unauthorised-page" */ '@/views/errors/UnauthorisedPage')
const ShowBooking = () => import(/* webpackChunkName: "show-booking-page" */ '@/views/bookings/ShowBooking')
const Vehicles = () => import(/* webpackChunkName: "vehicles-page" */ '@/views/vehicles/Vehicles')
const Roles = () => import(/* webpackChunkName: "roles-page" */ '@/views/roles/Roles')
const ShowRole = () => import(/* webpackChunkName: "show-role-page" */ '@/views/roles/ShowRole')
const Availabilities = () => import(/* webpackChunkName: "availabilities-page" */ '@/views/availabilities/Availabilities')
const Manifests = () => import(/* webpackChunkName: "manifests-page" */ '@/views/manifests/Manifests')
const ShowManifest = () => import(/* webpackChunkName: "show-manifest-page" */ '@/views/manifests/ShowManifest')
const ManifestDriverView = () => import(/* webpackChunkName: "manifest-driver-page" */ '@/views/manifests/ManifestDriverView')
const AdminUsers = () => import(/* webpackChunkName: "admin-users-page" */ '@/views/admin-users/AdminUsers')
const Customers = () => import(/* webpackChunkName: "customers-page" */ '@/views/customers/Customers')
const ShowCustomer = () => import(/* webpackChunkName: "show-customer-page" */ '@/views/customers/ShowCustomer')
const Services = () => import(/* webpackChunkName: "services-page" */ '@/views/services/Services')
const DiscountCodes = () => import(/* webpackChunkName: "discount-codes-page" */ '@/views/discount-codes/DiscountCodes')
const AuthCallbackPage = () => import(/* webpackChunkName: "auth-callback-page" */ '@/views/AuthCallbackPage')

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      beforeEnter: middleware.auth
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      beforeEnter: middleware.auth
    },
    {
      path: '/admin-users',
      name: 'admin-users',
      component: AdminUsers,
      beforeEnter: middleware.auth
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments,
      beforeEnter: middleware.auth
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: Bookings,
      beforeEnter: middleware.auth
    },
    {
      path: '/bookings/:bookingId',
      name: 'bookings.show',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true
    },
    {
      path: '/bookings/:bookingId/timeline',
      name: 'bookings.timeline',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingTimeline' }
    },
    {
      path: '/bookings/:bookingId/files',
      name: 'bookings.files',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingFiles' }
    },
    {
      path: '/bookings/:bookingId/candr',
      name: 'bookings.candr',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingCR' }
    },
    {
      path: '/bookings/:bookingId/workshop',
      name: 'bookings.workshop',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingWorkshop' }
    },
    {
      path: '/bookings/:bookingId/qc',
      name: 'bookings.qc',
      component: ShowBooking,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingQC' }
    },
    {
      path: '/booking-bikes',
      name: 'booking-bikes',
      component: BookingBikes,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'BookingQC' }
    },
    {
      path: '/vehicles',
      name: 'vehicles',
      component: Vehicles,
      beforeEnter: middleware.auth
    },
    {
      path: '/manifests',
      name: 'manifests',
      component: Manifests,
      beforeEnter: middleware.auth
    },
    {
      path: '/manifests/:manifestId',
      name: 'manifests.show',
      component: ShowManifest,
      props: true,
      beforeEnter: middleware.auth
    },
    {
      path: '/manifests/:manifestId/driver-view',
      name: 'manifests.driver-view',
      component: ManifestDriverView,
      props: true,
      beforeEnter: middleware.auth
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
      beforeEnter: middleware.auth
    },
    {
      path: '/roles/:roleId',
      name: 'roles.show',
      component: ShowRole,
      props: true,
      beforeEnter: middleware.auth
    },
    {
      path: '/availabilities',
      name: 'availabilities',
      component: Availabilities,
      beforeEnter: middleware.auth
    },
    {
      path: '/customers',
      name: 'customers',
      component: Customers,
      beforeEnter: middleware.auth
    },
    {
      path: '/customers/:customerId',
      name: 'customers.show',
      component: ShowCustomer,
      beforeEnter: middleware.auth,
      props: true
    },
    {
      path: '/customers/:customerId/timeline',
      name: 'customers.timeline',
      component: ShowCustomer,
      beforeEnter: middleware.auth,
      props: true,
      meta: { innerComponent: 'CustomerTimeline' }
    },
    {
      path: '/services',
      name: 'services',
      component: Services,
      beforeEnter: middleware.auth
    },
    {
      path: '/discount-codes',
      name: 'discount-codes',
      component: DiscountCodes,
      beforeEnter: middleware.auth
    },
    {
      path: '/auth/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: middleware.guest
    },
    {
      path: '/auth/callback',
      name: 'auth.callback',
      component: AuthCallbackPage
    },
    {
      path: '/errors/unauthorised',
      name: 'error-403',
      component: UnauthorisedPage
    },
    {
      path: '*',
      name: 'error-404',
      component: NotFoundPage
    }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
