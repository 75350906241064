<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.92"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Atlas Version Available! 🥳</span>
        </v-card-title>
        <v-card-text>
          <p>Hi {{ $auth.user.first_name }}, you are currently running a slightly older version of Atlas <small>({{ $release.version }})</small>. The good news is, it's simple to upgrade - just click the upgrade button below!</p>

          <p><small><i>If you are still seeing this message after upgrading, please hold (Control + Shift + R) to perform a hard refresh.</i></small></p>

          <v-divider class="mb-4 mt-6" />

          <vue-markdown v-if="release" class="mt-6 mb-4 markdown">
            {{ release.description }}
          </vue-markdown>

          <v-btn
            v-if="release"
            class="mt-8"
            color="primary"
            depressed
            block
            :loading="loading"
            @click="upgrade"
          >
            Upgrade to Atlas {{ release.version }}
          </v-btn>
          <v-btn
            class="mt-4"
            color="primary"
            small
            text
            block
            @click="closeDialog"
          >
            Remind me later
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'NewAppReleaseDialog',

  mixins: [ HasDialog ],

  components: {
    VueMarkdown
  },

  props: {
    release: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return  {
      loading: false
    }
  },

  methods: {
    upgrade () {
      this.loading = true
      location.reload(true)
    }
  }
}
</script>
