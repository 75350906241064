const Pusher = require('pusher-js')
import store from './store'
import Echo from "laravel-echo"

export default {
  init () {
    if (process.env.VUE_APP_WEBSOCKET_KEY && store.getters['auth/accessToken']) {
      var PusherClient = new Pusher(process.env.VUE_APP_WEBSOCKET_KEY, {
        authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/api/broadcasting/auth`,
        cluster: process.env.VUE_APP_WEBSOCKET_CLUSTER,
        encrypted: true,
        auth: {
          headers: {
            Authorization: `Bearer ${store.getters['auth/accessToken']}`
          }
        }
      })

      window.Pusher = Pusher
      window.Echo = new Echo({
          broadcaster: 'pusher',
          client: PusherClient
      })
    }
  }
}