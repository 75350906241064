<template>
  <v-alert
    dense
    outlined
    class="mt-4 mb-0"
    color="primary"
  >
<!--     <v-checkbox
      v-model="form.send_payment_link"
      label="Send Payment Link?"
    />
    <v-checkbox
      v-model="form.send_payment_link_reminders"
      label="Send Payment Link Reminders?"
    />
    <v-checkbox
      v-model="form.skip_awaiting_payment"
      label="Skip Payment Before Confirmed?"
    /> -->

    <v-row class="mt-4">
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="form.channel"
          :items="[{ 'value': 'CHANNEL_EMAIL', 'text': 'Email' }, { 'value': 'CHANNEL_EVENT', 'text': 'Event' }, { 'value': 'CHANNEL_CRM', 'text': 'Other' }, { 'value': 'CHANNEL_PHONE', 'text': 'Phone' }]"
          label="Channel"
          :rules="[v => !!v || 'Please choose a collection address']"
          required
          outlined
          dense
        />
      </v-col>
    </v-row>
  </v-alert>
</template>
<script type="text/javascript">
export default {
  name: 'AddBookingSettings',

  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
