import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const debug = ['development', 'staging'].includes(process.env.NODE_ENV)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: debug ? '#ff941c' : '#00a09b',
        secondary: '#ff941c',
        accent: debug ? '#ff941c' : '#00a09b'
      },
      dark: {
        primary: debug ? '#ff941c' : '#00a09b',
        secondary: '#ff941c',
        accent: debug ? '#ff941c' : '#00a09b'
      }
    }
  }
});
