<template>
  <v-app>
    <AppNavigation :drawer.sync="drawer" />

    <AppBar v-if="$auth.user.id" :drawer.sync="drawer" />

    <AuthModal />

    <v-main>
      <router-view/>
    </v-main>
    
    <AddBookingFab v-if="$auth.user.id"/>

    <NewAppReleaseDialog
      :dialog.sync="releaseDialog"
      :release="newRelease"
    />

    <div class="app_version">
      <p>havebike Atlas {{ $release.version }}</p>
    </div>
  </v-app>
</template>

<script>
import AuthModal from '@/components/AuthModal'
import AppBar from '@/components/layout/AppBar'
import AddBookingFab from '@/components/bookings/AddBookingFab'
import AppNavigation from '@/components/layout/AppNavigation'
import NewAppReleaseDialog from '@/components/NewAppReleaseDialog'

export default {
  name: 'App',

  components: {
    AuthModal,
    AppBar,
    AppNavigation,
    AddBookingFab,
    NewAppReleaseDialog
  },

  data: () => ({
    drawer: false,
    newRelease: null,
    releaseDialog: false
  }),

  mounted () {
    this.versionCheck()
  },

  methods: {
    versionCheck () {
      this.$api.get({
        path: 'app-releases/latest',
        params: {
          app: 'APP_ATLAS'
        }
      })
        .then(({ data: release }) => {
          if ((this.$moment(release.published_at) > this.$moment(this.$release.published_at)) && release.version !== this.$release.version) {
            this.newRelease = release
            this.releaseDialog = true
          } else {
            setTimeout(() => {
              this.versionCheck()
            }, 300000)
          }
        })
    }
  }
}
</script>
<style type="text/css">
  body {
    padding: 0;
  }
  .bg-primary {
    background: #00a09b;
  }
  .bg-gray {
    background: #545454;
  }
  .text-white {
    color: white;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .w-full {
    width: 100%;
  }
  .align-items-center {
    align-items: center;
  }
  .app_version {
    font-size: 12px;
    color: grey;
    padding: 56px 0px 0px 10px;
    display: block;
  }
  @media screen and (max-width: 1259px) {
    .v-autocomplete__content {
      max-width: 100vw;
    }
  }
  @media screen and (min-width: 1260px) {
    .v-autocomplete__content {
      max-width: 50vw;
    } 
  }
</style>
