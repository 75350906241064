import CryptoJS from 'crypto-js'

  function generateCodeVerifier () {
    return generateRandomString(128)
  }

  function generateRandomString(length) {
    var text = ""
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~"
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  function generateCodeChallenge(code_verifier) {
    return base64URL(CryptoJS.SHA256(code_verifier))
  }

  function base64URL(string) {
      return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }

  export default {
    generateCodeVerifier: generateCodeVerifier,
    generateRandomString: generateRandomString,
    generateCodeChallenge: generateCodeChallenge,
    base64URL: base64URL,
  }