<template>
  <div>
    <v-row
      class="py-0"
      v-if="firstWeekCollectionDays.length"
    >
      <v-col class="py-0">
        <h3 class="primary--text">3. {{ form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'Visit' : 'Collection' }} Date</h3>
        <p>Please choose a {{ form.job_type === 'JOB_TYPE_FLEET_ONSITE' ? 'visit' : 'collection' }} date below.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0">
        <v-radio-group
          v-if="firstWeekCollectionDays.length > 0 && !showFutureCollectionDates"
          v-model="form.collection_date"
          row
          dense
        >
          <div
            v-for="(day, key) in firstWeekCollectionDays"
            :key="key"
            class="d-flex pr-4"
          >
            <v-radio
              :key="day.date.format('YYYY-MM-DD')"
              :label="day.date.format('ddd Do MMM')"
              :value="day.date.format('YYYY-MM-DD')"
              :disabled="!day.available"
              class="mr-2"
            />
            <BookingDateSelectorTooltip :day="day" />
          </div>
        </v-radio-group>

        <v-dialog
          v-if="showFutureCollectionDates"
          ref="futureCollectionDatesDialog"
          v-model="collectionDateModal"
          :return-value.sync="form.collection_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="$moment(form.collection_date).format('ddd Do MMM')"
              class="mt-4"
              label="Collection Date"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            scrollable
            v-model="form.collection_date"
            :min="$moment().add(1, 'day').format('YYYY-MM-DD')"
            :allowed-dates="pickerAllowedCollectionDates"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="collectionDateModal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.futureCollectionDatesDialog.save(form.collection_date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>

        <a
          v-if="firstWeekCollectionDays.length > 0"
          @click="showFutureCollectionDates = !showFutureCollectionDates"
        >
          {{ showFutureCollectionDates ? 'Show Dates This Week?' : 'Show Later Dates?' }}
        </a>
      </v-col>
    </v-row>

    <v-row
      class="pb-0"
      v-if="firstWeekCollectionDays.length && form.job_type !== 'JOB_TYPE_FLEET_ONSITE'"
    >
      <v-col class="pb-0">
        <h3 class="primary--text">4. Return Date</h3>
        <p>Please choose a return date below.</p>
      </v-col>
    </v-row>

    <v-row v-if="form.job_type !== 'JOB_TYPE_FLEET_ONSITE'">
      <v-col class="py-0">
        <v-radio-group
          v-if="firstWeekReturnDays.length > 0"
          v-model="form.return_date"
          row
          dense
        >
          <div
            v-for="(day, key) in firstWeekReturnDays"
            :key="key"
            class="d-flex pr-4"
          >
            <v-radio
              :key="day.date.format('YYYY-MM-DD')"
              :label="day.date.format('ddd Do MMM')"
              :value="day.date.format('YYYY-MM-DD')"
              :disabled="!day.available"
              class="mr-2"
            />
            <BookingDateSelectorTooltip :day="day" />
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import filter from 'lodash.filter'
import BookingDateSelectorTooltip from '@/components/bookings/BookingDateSelectorTooltip'

export default {
  name: 'BookingDatesSelector',

  components: {
    BookingDateSelectorTooltip
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    postcodeData: {
      type: Object,
      required: true
    },
    collectionDays: {
      type: Array,
      required: true
    },
    returnDays: {
      type: Array,
      required: true
    },
  },

  data () {
    return {
      showFutureCollectionDates: false,
      collectionDateModal: null
    }
  },

  watch: {
    'form.collection_date': function () {
      this.$nextTick(() => {
        var dates = filter(this.firstWeekReturnDays, day => {
          return day.available
        })
        if (dates[0]) {
          this.$emit('update:return_date', dates[0].date)          
        }
      })
    }
  },

  computed: {
    firstWeekCollectionDays () {
      var days = []
      this.collectionDays.slice(0, 7).forEach(day => {
        days.push({...day, ...{ date: this.$moment(day.date) }})
      })
      return days
    },

    firstWeekReturnDays () {
      var days = []
      this.returnDays.forEach((day) => {
        var momentDate = this.$moment(day.date)
        if (this.$moment(this.form.collection_date).add(2, 'day') < momentDate) {
          days.push({...day, ...{ date: momentDate }})
        }
      })
      return days.slice(0, 7)
    }
  },

  methods: {
    clear () {
      this.showFutureCollectionDates = false
      this.collectionDateModal = null
    },

    pickerAllowedCollectionDates (val) {
      var date = this.$moment(val).format('YYYY-MM-DD')
      var dates = filter(this.collectionDays, day => {
        return this.$moment(day.date).format('YYYY-MM-DD') === date
      })
      if (dates[0]) {
        return dates[0].available
      }
      return false
    }
  }
}
</script>
