import axios from 'axios'

// initial state
const state = {
  darkMode: false,
  user: {}
}

// getters
const getters = {
  darkMode: (state) => {
    return state.darkMode
  },

  user: state => {
    return state.user
  },

  userPermissions: (state, getters) => {
    var permissions = []
    if (getters.user.roles) {
      getters.user.roles.forEach(role => {
        role.permissions.forEach(permission => {
          permissions.push(permission.name)
        })
      })
    }
    return permissions
  },

  hasPermission: (state, getters) => (permission) => {
    var hasRole = false
    if (getters.userPermissions) {
      getters.userPermissions.forEach(userPermission => {
        if (userPermission === permission) {
          hasRole = true
        }
      })
    }
    return hasRole
  }
}

// actions
const actions = {
  getUser ({ commit, rootGetters }, token = null) {
    return new Promise((resolve, reject) => {
      token = token || rootGetters['auth/accessToken']
      var axiosInstance = axios.create({
        headers: {
          common: {
            'Authorization': `Bearer ${token}`,
          }
        }
      })
      axiosInstance.get(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
        params: {
          with: ['roles.permissions']
        }
      })
        .then(response => {
          commit('setUser', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeAllUsers ({ state }) {
    state.user = {}
  },

  updateUser ({ commit }, obj) {
    return new Promise((resolve) => {
      commit('setUser', obj)
      resolve()
    })
  }
}

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setDarkMode (state, mode) {
    state.darkMode = mode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
