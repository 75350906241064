import axios from 'axios'

// initial state
const state = {
  hubs: [],
  currentHub: {}
}

// getters
const getters = {
  currentHub: (state) => { return state.currentHub },

  hubs: (state) => { return state.hubs }
}

// actions
const actions = {
  getHubs ({ commit, rootGetters, getters }) {
    return new Promise((resolve, reject) => {
      var axiosInstance = axios.create({
        headers: {
          common: {
            'Authorization': `Bearer ${rootGetters['auth/accessToken']}`,
          }
        }
      })
      axiosInstance.get(`${process.env.VUE_APP_AUTH_BASE_URL}/api/user/hubs`)
        .then(({ data: { data: hubs } }) => {
          commit('setHubs', hubs)
          if (!getters.currentHub.id) {
            commit('setCurrentHub', hubs[0])            
          }
          resolve(hubs)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  setHubs (state, hubs) {
    state.hubs = hubs
  },

  setCurrentHub (state, hub) {
    state.currentHub = hub
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
