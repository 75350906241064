import store from '@/store/'

export default {
  auth (to, from, next) {
    if (store.getters['auth/isAuthenticated']) {
      next()
    } else {
      next({ name: 'login', query: { intended: window.location.href }})
    }
  },

  guest (to, from, next) {
    if (store.getters['auth/isAuthenticated']) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  }
}
