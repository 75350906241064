<template>
  <div>
    <v-alert
      v-if="algoliaErrorMessage && showErrors"
      outlined
      text
      type="error"
    >
      {{ algoliaErrorMessage }}
    </v-alert>
    <v-text-field
      v-if="(current ? current.id : false) && !currentFocus"
      ref="current"
      :value="current[itemText]"
      :label="label"
      :hide-details="hideDetails"
      :background-color="backgroundColour"
      :placeholder="placeholder"
      :full-width="fullWidth"
      :disabled="disabled"
      outlined
      :dense="dense"
      clearable
      :prepend-inner-icon="icon"
      @focus="focusOnAutoComplete"
    />
    <v-autocomplete
      v-else
      v-model="localValue"
      v-bind="$props"
      ref="autocomplete"
      :loading="loading"
      :items="items"
      :disabled="disabled"
      :search-input.sync="search"
      :label="label"
      :item-text="itemText"
      :multiple="multiple"
      :chips="multiple"
      :hide-details="hideDetails"
      :append-icon="null"
      :background-color="backgroundColour"
      :prepend-inner-icon="icon"
      :light="light"
      :dark="dark"
      :placeholder="placeholder"
      :full-width="fullWidth"
      :allow-overflow="false"
      :dense="dense"
      no-filter
      outlined
      clearable
      hide-selected
      hide-no-data
      return-object
      class="algolia_autocomplete"
      @change="handleChange"
      @blur="resetCurrent"
    >
      <template v-slot:no-data>
        <slot name="empty" />
      </template>
      <template v-slot:item="data">
        <slot v-bind="data" name="above-search-result" />
        <div class="search_result">
          <slot v-bind="data" />
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch'

export default {
  name: 'AlgoliaSearch',

  props: {
    index: {
      required: false,
      type: String,
      default: () => { return null }
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: () => { return false }
    },

    label: {
      required: true,
      type: String
    },

    value: {
      required: true
    },

    itemText: {
      type: String,
      required: false,
      default: () => { return 'name' }
    },

    multiple: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    dense: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },

    current: {
      type: Object,
      required: false,
      default: () => { return {} }
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    light: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    dark: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    fullWidth: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    backgroundColour: {
      type: String,
      required: false,
      default: () => { return undefined }
    },

    placeholder: {
      type: String,
      required: false,
      default: () => { return undefined }
    },

    icon: {
      type: String,
      required: false,
      default: () => { return 'mdi-database-search' }
    },

    multiIndex: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    multiQueries: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    requestParams: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },

  data () {
    return {
      currentFocus: false,
      algoliaClient: null,
      algoliaIndex: null,
      algoliaErrorMessage: null,
      loading: false,
      items: [],
      search: null,
    }
  },

  watch: {
    search () {
      this.algoliaSearch()
    },

    index () {
      this.clearSearch()
      this.initIndex()
    }
  },

  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    }
  },

  mounted () {
    this.algoliaClient = algoliasearch(
      this.$algolia.app_id,
      this.$algolia.key
    )
    if (!this.multiIndex) {
      this.initIndex()
    }
  },

  methods: {
    handleChange ($event) {
      this.$emit('change', $event)
    },

    focus () {
      this.$refs.autocomplete.focus()
    },

    initIndex () {
      this.algoliaIndex = this.algoliaClient.initIndex(this.index)
    },

    clearSearch () {
      this.search = null
      this.localValue = null
    },

    resetCurrent () {
      this.currentFocus = false
      this.$nextTick(() => {
        // this.$refs.current.focus()
      })
    },

    focusOnAutoComplete () {
      this.currentFocus = true
      this.search = this.current[this.itemText]
      this.$nextTick(() => {
        this.$refs.autocomplete.focus()
      })
    },

    algoliaSearch () {
      this.algoliaErrorMessage = null
      if (this.search === '' || this.string === null) {
        this.items = []
      } else if (!this.multiIndex) {
        this.algoliaIndex.search(this.search, this.requestParams)
          .then(({ hits }) => {
            this.items = hits
          })
          .catch((error) => {
            this.algoliaErrorMessage = error.message
          })
      } else {
        var queries = []
        this.multiQueries.forEach(query => {
          queries.push({
            ...query,
            ...{ query: this.search }
          })
        })
        this.algoliaClient.multipleQueries(queries)
          .then(({ results }) => {
            var hits = []
            results.forEach(result => {
              hits = [
                ...hits,
                ...result.hits
              ]
            })
            this.items = hits
          })
          .catch((error) => {
            this.algoliaErrorMessage = error.message
          })
      }
    }
  }
}
</script>

<style lang="css">
  .search_highlight em {
    background: rgb(1 160 155 / 28%);
  }
  .search_result {
    border-bottom: 3px dashed #d8d8d8;
    display: inherit;
    width: 100%;
  }
</style>
