<template>
  <div>
    <v-divider
      v-if="$vnode.key > 0"
      class="mb-5 mt-3"
    />

    <v-row  
      v-if="$vnode.key"
      class="pt-0"
    >
      <v-col class="pt-0 mt-4">
        <h4 class="primary--text">Bike {{ $vnode.key + 1 }}</h4>
        <p>{{ bike.manafacturer }} {{ bike.model }}</p>
      </v-col>
    </v-row>
    <v-row  
      v-else
      class="pt-0"
    >
      <v-col class="pt-0">
        <h4 class="primary--text">Bike 1</h4>
        <p>{{ bike.manafacturer }} {{ bike.model }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-select
          v-model="bike.service_sku"
          :rules="[v => !!v || 'Service is required']"
          :items="services"
          :item-text="service => { return `${service.name} (${service.sku}) - ${$currency(service.price)}` }"
          item-value="sku"
          label="Service"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="!bike.unknown_details"
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="bike.manafacturer"
          :rules="[v => !!v || 'Manafacturer is required']"
          :autofocus="autofocus"
          :label="['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type) ? 'Visit Name' : 'Manafacturer'"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        v-if="!bike.unknown_details && !['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type)"
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="bike.model"
          :rules="[v => !!v || 'Model is required']"
          label="Model"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        v-else-if="['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type)"
        cols="6"
      >
        <v-textarea
          v-model="bike.customer_notes"
          rows="2"
          label="Visit Notes"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row v-if="!['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type)">
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="bike.colour"
          label="Colour"
          outlined
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="bike.type"
          :rules="[v => !!v || 'Type is required']"
          :items="services[0].available_types"
          item-text="value"
          item-value="key"
          label="Type"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>

    <v-row v-if="!['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type)">
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="bike.additional_authorised_spend"
          type="number"
          step="0.01"
          min="0"
          prefix="£"
          label="Authorised Spend"
          hint="A brief description of the sundry. Why is this needed?"
          outlined
          dense
        />
      </v-col>

      <v-col
        cols="9"
      >
        <v-textarea
          v-model="bike.customer_notes"
          rows="2"
          label="Customer Notes"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row v-if="!['JOB_TYPE_FLEET_ONSITE', 'JOB_TYPE_FLEET_ONSITE_COMPLAINT'].includes(bike.job_type)">
      <v-col
        v-if="!hideJobType"
        cols="12"
        sm="6"
      >
        <v-select
          v-model="bike.job_type"
          :rules="[v => !!v || 'Job type is required']"
          :items="jobTypes"
          label="Job Type"
          item-text="label"
          item-value="value"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <AlgoliaSearch
          v-model="bike.asset"
          ref="algoliaSearch"
          label="Asset"
          index="assets"
          item-text="number"
          item-value="id"
        >
          <template slot-scope="data">
            <v-list-item-content>
              <v-list-item-title class="search_highlight"><strong v-html="data.item._highlightResult.number.value" /></v-list-item-title>
              <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.manafacturer.value" />
              <v-list-item-subtitle class="search_highlight" v-html="data.item._highlightResult.assetable_name.value" />
            </v-list-item-content>
          </template>
        </AlgoliaSearch>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import AlgoliaSearch from '@/components/algolia-search/AlgoliaSearch'

export default {
  name: 'BikeFormFields',

  components: {
    AlgoliaSearch
  },

  props: {
    autofocus: {
      type: Boolean,
      required: false,
      default: () => { return true }
    },
    bike: {
      type: Object,
      required: true
    },
    services: {
      type: Array,
      required: true
    },
    jobTypes: {
      type: Array,
      required: true
    },
    hideJobType: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  }
}
</script>
