<template>
  <div>

    <v-dialog
      v-model="$auth.showAuthModal"
      persistent
      max-width="600px"
    >
      <v-form
        ref="form"
        v-model="validForm"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h2>{{ __('Confirm Password') }}</h2>
                  <p>{{ __("For security reasons, we need to confirm it's really you! Please enter your password below.") }}</p>
                  <a
                    href="#"
                    style="text-decoration: none;"
                    @click="$auth.logout(); $store.commit('auth/hideAuthModal')"
                  >
                    {{ __('Not') }} {{ $auth.user ? $auth.user.name : '' }}? {{ __('Logout') }}
                  </a>
                </v-col>
                <v-col
                  class="pb-0"
                  cols="12"
                >
                  <v-text-field
                    v-model="form.password"
                    :rules="[v => !!v || __('Password is required')]"
                    :label="__('Password')"
                    type="password"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col
                  class="pt-0"
                  cols="12"
                >
                  <v-btn
                    color="primary"
                    large
                    block
                    @click="submitPassword"
                  >
                    {{ __('Submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar
      v-model="showError"
      color="error"
    >
      {{ error }}
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: 'AuthModal',

  data () {
    return {
      form: {
        username: null,
        password: null
      },
      showError: false,
      error: null,
      validForm: true
    }
  },

  mounted () {
    if (this.$auth.user) {
      this.form.username = this.$auth.user.email      
    }
  },

  methods: {
    submitPassword () {
      if (this.$refs.form.validate()) {
        this.$auth.login(this.form)
          .then(() => {
            this.form.passsword = null
          })
          .catch(error => {
            this.showError = true
            this.form.password = null
            this.error = error.response.data.message
            this.$refs.form.resetValidation()
          })
      }
    }
  }
}
</script>
<style lang="scss">
  .auth-modal {
    padding: 40px 60px;
    box-shadow: #9e9e9e52 1px 1px 3px 0px;
    background: white;
    z-index: 1000;
    position: absolute;
    max-width: 500px;
    width: 90%;
    margin: auto;
    display: block;
    top: 10%;
    left: 0;
    right: 0;
  }
</style>
