<template>
  <div>
    <v-app-bar
      v-if="$route.name !== 'login' && $route.name !== 'auth.callback'"
      color="primary"
      height="33px"
      dense
      dark
      app
    >
      <div
        style="height: 100%;"
        class="w-full d-flex justify-content-between"
      >
        <div class="align-items-center d-flex">
          <v-icon left small>mdi-domain</v-icon>
          <small>{{ $hubs.currentHub.name }}</small>
        </div>
        <div class="align-items-center d-flex">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn
                width="300px"
                outlined
                dark
                x-small
                v-on="on"
              >
                  <v-avatar size="14" color="primary" class="mr-2">
                    <img
                      :src="$auth.user.profile_img_url"
                      :alt="$auth.user.name">
                  </v-avatar>
                  {{ $auth.user.name }}
                <v-icon right small>mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title>
                    <v-avatar size="20" class="mr-2">
                      <img
                        :src="$auth.user.profile_img_url"
                        :alt="$auth.user.name"
                      >
                    </v-avatar>
                    {{ $auth.user.name }}
                  </v-list-item-title>
                </template>
                <v-list-item
                  @click="$auth.logout()"
                >
                  <v-list-item-title>
                    <v-icon left small>mdi-logout</v-icon>
                    Logout
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title>
                    <v-icon left>mdi-domain</v-icon>
                    Hubs
                  </v-list-item-title>
                </template>
                <v-list-item v-for="hub in $hubs.hubs" :key="hub.id" :class="{ 'green lighten-4': $hubs.currentHub.id === hub.id }" @click="changeHub(hub)">
                  <v-list-item-title>
                    {{ hub.name }} <small v-if="$hubs.currentHub.id === hub.id"><strong>(selected)</strong></small>
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-app-bar
      v-if="$route.name !== 'login' && $route.name !== 'auth.callback'"
      style="margin-top: 33px;"
      color="primary"
      app
      dark
    >
      <div
        style="height: 100%;"
        class="w-full d-flex justify-content-between"
      >
        <div class="d-flex align-items-center">
          <v-app-bar-nav-icon @click.stop="$emit('update:drawer', !drawer)" />
          <router-link :to="{ name: 'dashboard' }">
            <img src="@/assets/hb-logo-white-cropped.png" class="appbar_logo">
          </router-link>
        </div>
        <div class="d-flex align-items-center app_bar_search">
          <AppSearch />
        </div>
<!--         <div v-if="!$app.buildIsDebug" class="align-items-center d-none d-md-flex text-overline">
          You are in {{ $app.buildMode }} mode!
        </div> -->
        <div class="align-items-center d-none d-md-flex">

        </div>
      </div>
    </v-app-bar>
    <div v-if="$route.name !== 'login' && $route.name !== 'auth.callback'" style="padding-top: 35px;">
    </div>
  </div>
</template>
<script type="text/javascript">
import AppSearch from '@/components/layout/AppSearch'

export default {
  name: 'AppBar',

  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },

  components: {
    AppSearch
  },

  methods: {
    changeHub (hub) {
      this.$hubs.setCurrentHub(hub)
      window.location.reload()
    }
  }
}
</script>
<style type="text/css">
  .appbar_logo {
    width: 110px;
    height: auto;
  }
  .app_bar_search {
    width: 600px;
  }
  .app_bar_search span:first-child {
    width: 100%;
  }
</style>
