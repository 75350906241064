<template>
  <div>
    <v-btn
      color="primary"
      fab
      bottom
      right
      fixed
      @click="dialog = true"
    >
      <v-icon>mdi-plus-circle-outline</v-icon>
    </v-btn>
    <AddBookingDialog
      :dialog.sync="dialog"
    />
  </div>
</template>
<script type="text/javascript">
import AddBookingDialog from '@/components/bookings/AddBookingDialog'

export default {
  name: 'AddBookingFab',

  components: {
    AddBookingDialog
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>
